import 'loadjs';
import u from 'umbrellajs';
import 'lazysizes';
import '../css/config.scss';

(function ($) {
  'use strict';

  // Toggle main menu
  $('.js-menu').handle('click', function () {
    $('.js-nav').toggleClass('hidden');
    $('main').toggleClass('h-0 overflow-y-hidden');
    $('footer').toggleClass('h-0 overflow-y-hidden');
  });
})(u);
